import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";

const TimezoneSelect = ({ timezone, setTimezone }) => {
  const [timezones, setTimezones] = useState([]);
  const [filteredTimezones, setFilteredTimezones] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Get a list of timezones with correctly formatted offsets
    const tzList = moment.tz.names().map((tz) => {
      const offsetMinutes = moment.tz(tz).utcOffset();
      const hours = Math.floor(Math.abs(offsetMinutes) / 60);
      const minutes = Math.abs(offsetMinutes) % 60;
      const sign = offsetMinutes >= 0 ? "+" : "-";
      const formattedOffset = `(UTC${sign}${hours}:${minutes === 0 ? "00" : minutes})`;

      return { name: tz, label: `${tz} ${formattedOffset}`, offset: offsetMinutes };
    });

    // Sort timezones by UTC offset
    tzList.sort((a, b) => a.offset - b.offset);

    setTimezones(tzList);
    setFilteredTimezones(tzList);

    // Set default timezone to user's local timezone
    if (!timezone)
      setTimezone(moment.tz.guess());
  }, [setTimezone]);

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredTimezones(timezones);
    } else {
      setFilteredTimezones(
        timezones.filter((tz) =>
          tz.label.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, timezones]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: "relative", width: "340px" }}>
      {/* Select Button */}
      <button
        onClick={() => setOpen((prev) => !prev)}
        style={{
          width: "100%",
          padding: "12px 16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#fff",
          textAlign: "left",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          transition: "border 0.2s ease-in-out",
          fontSize: "14px",
        }}
      >
        <span>{timezone || "Select Timezone"}</span>
        <span style={{ marginLeft: "10px", fontSize: "14px", color: "#555" }}>
          {open ? "▲" : "▼"}
        </span>
      </button>

      {/* Dropdown List */}
      {open && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            zIndex: 1000,
            maxHeight: "250px",
            overflowY: "auto",
            marginTop: "4px",
            animation: "fadeIn 0.2s ease-in-out",
          }}
        >
          {/* Search Input */}
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              border: "none",
              borderBottom: "1px solid #ddd",
              outline: "none",
              fontSize: "14px",
              borderRadius: "8px 8px 0 0",
            }}
            placeholder="Search timezone..."
            autoFocus
          />

          {/* Timezone List */}
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            {filteredTimezones.length > 0 ? (
              filteredTimezones.map((tz) => (
                <li
                  key={tz.name}
                  onClick={() => {
                    setTimezone(tz.name);
                    setOpen(false);
                    setSearch("");
                  }}
                  style={{
                    padding: "12px 16px",
                    cursor: "pointer",
                    fontSize: "14px",
                    transition: "background 0.2s ease-in-out",
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "#f5f5f5")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
                >
                  {tz.label}
                </li>
              ))
            ) : (
              <li style={{ padding: "12px 16px", color: "#888" }}>
                No results found
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TimezoneSelect;

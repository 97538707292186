import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderOne from "../../header/HeaderOne";
import Breadcrumb from "../../components/BreadCurmb";
import ReactMarkdown from 'react-markdown';
import { Link } from "react-router-dom";
import './BlogPost.css';
import RecommendedArticles from "./RecommendedArticles";
import CtaBanner from "./CtaBanner";

export default function BlogPost() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [recommended, setRecommended] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const res = await fetch(`${global.SERVIP}/blog-controller/${slug}`);
        if (!res.ok) throw new Error("Failed to fetch blog");
        const data = await res.json();
        setBlog(data.blog || data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchRecommended = async () => {
      try {
        const res = await fetch(`${global.SERVIP}/blog-controller/recent/get`);
        if (!res.ok) throw new Error("Failed to fetch recommended blogs");
        const data = await res.json();
        console.log(data.blogs)
        setRecommended(data || []);
      } catch (err) {
        console.error("Error fetching recommended blogs:", err);
      }
    };

    fetchBlog();
    fetchRecommended();
  }, [slug]);


  if (loading) {
    return (
      <>
        <HeaderOne />
        <Breadcrumb title="Loading..." subtitle="Please wait" pagetitle="Loading Blog" />
        <div className="container-blog py-5">
          <h2>Loading blog post...</h2>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <HeaderOne />
        <Breadcrumb
          title="Error"
          subtitle="Unable to load blog post"
          pagetitle="Error"
        />
        <div className="container-blog py-5">
          <h4 className="text-danger">Error: {error}</h4>
        </div>
      </>
    );
  }

  if (!blog) {
    return (
      <>
        <HeaderOne />
        <Breadcrumb title="Not Found" subtitle="" pagetitle="404" />
        <div className="container-blog py-5">
          <h4>Blog not found.</h4>
        </div>
      </>
    );
  }

  // Destructure the fields from your blog object
  const {
    title,
    subtitle,
    excerpt,
    coverImage,
    category,
    tags,
    content,
    createdAt,
    writer,
    writerImg,
    writerDesc,
    views
  } = blog;

  return (
    <>
      <HeaderOne />
  
      <section
        className="blog-post-area pt-120 pb-75 pt-md-60 pb-md-15 pt-xs-60 pb-xs-15"
        style={{
          marginTop: "14%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="container-blog"
        
        >
          {/* === Main Content Section === */}
          <div
            className="main-content"
           
          >
            <div className="row justify-content-center mb-15">
              <div className="col-lg-10 col-md-10">
                <div className="blog-details">
                  {recommended && recommended.length > 0 && (
                    <RecommendedArticles recommended={recommended} />
                  )}
  
                  {/* Call-to-Action */}
                  <div className="cta-banner text-center mb-40">
                    <h4>Try TextLink for Free</h4>
                    <p style={{ marginTop: 10 }}>
                      Sign up today and get 500 messages for free to explore seamless communication.
                    </p>
                    <Link
                      to="/signup"
                      className="btn"
                      style={{
                        display: "inline-block",
                        padding: "10px 20px",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#007bff",
                        textTransform: "uppercase",
                        border: "1px solid #007bff",
                        borderRadius: "6px",
                        textDecoration: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        marginTop: 10,
                      }}
                      onMouseEnter={(e) => (
                        (e.target.style.backgroundColor = "#007bff"),
                        (e.target.style.color = "#fff")
                      )}
                      onMouseLeave={(e) => (
                        (e.target.style.backgroundColor = "transparent"),
                        (e.target.style.color = "#007bff")
                      )}
                    >
                      Try for Free
                    </Link>
                  </div>
  
                  {/* Blog Metadata */}
                  <div className="blog-meta mb-40">
                    <span>
                      <i className="fal fa-calendar-alt"></i>{" "}
                      {createdAt?.slice(0, 10) || "Unknown Date"}
                    </span>
                    <span style={{ marginLeft: 10 }}>{views} views</span>
                  </div>
  
                  {/* === Blog Content === */}
                  {content && (
  <div className="blog-content markdown-container">
    {(() => {
      try {
        const lines = content.split("\n");
        const titleLines = lines
          ?.map((line, index) => ({ line, index }))
          ?.filter(({ line }) => line?.trim()?.startsWith("#")) || [];

        const midpoint = titleLines[Math.floor((titleLines.length || 0) / 2)];

        return (
          <>
            {lines?.map((line, index) => (
              <React.Fragment key={index}>
                {midpoint?.index === index && (
                  <CtaBanner /> 
                )}
                <ReactMarkdown>{line}</ReactMarkdown>
              </React.Fragment>
            ))}
          </>
        );
      } catch (error) {
        console.error("Error rendering content:", error);
        return (
<ReactMarkdown> {content}</ReactMarkdown>
    )}
    })()}
  </div>
)}
                   {(
            <div
              className="writer-info mt-60"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                backgroundColor: "#f9f9f9",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Writer Image */}
              <img
                src={writerImg}
                alt={writer}
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />

              {/* Writer Description */}
              <div>
                <h4 style={{ marginBottom: "10px", fontSize: "18px", fontWeight: "600" }}>
                  {writer}
                </h4>
                <p style={{ fontSize: "14px", color: "#555", lineHeight: "1.6" }}>
                  {writerDesc}
                </p>
              </div>
            </div>

            
          )}
                    {/* Call-to-Action at the End */}
                    <div className="cta-banner text-center mt-40">
            <h4>Don't Miss Out!</h4>
            <p style={{marginTop:10}}>Start your free trial today and get 500 free messages with TextLink.</p>
            <Link
              to="/signup"
              className="btn"
              style={{
                display: "inline-block",
                padding: "10px 20px",
                fontSize: "14px",
                fontWeight: "600",
                color: "#007bff",
                textTransform: "uppercase",
                border: "1px solid #007bff",
                borderRadius: "6px",
                textDecoration: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                transition: "all 0.3s ease",
                marginTop:10

              }}
              onMouseEnter={(e) => (
                (e.target.style.backgroundColor = "#007bff"), (e.target.style.color = "#fff")
              )}
              onMouseLeave={(e) => (
                (e.target.style.backgroundColor = "transparent"), (e.target.style.color = "#007bff")
              )}
            >
              Try for Free
            </Link>
          </div>
                </div>
              </div>
            </div>
          </div>
  
          {/* === Sidebar Section === */}
          <div
            className="sidebar"
            style={{
              width: "320px",
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            {/* Recent Blogs */}
            <div
              className="recent-blogs"
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h3 style={{ fontSize: "18px", fontWeight: "700", marginBottom: "15px" }}>
                Recent Blogs
              </h3>
              <RecommendedArticles articles={recommended.blogs? recommended.blogs: []} />

            </div>
  
            {/* Recommended Articles */}
            {recommended && recommended.length > 0 && (
              <div
                className="recommended-articles"
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h3 style={{ fontSize: "18px", fontWeight: "700", marginBottom: "15px" }}>
                  Recommended Articles
                </h3>
                <RecommendedArticles articles={recommended.blogs? recommended.blogs: []} />
                </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
  
}

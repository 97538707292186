import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Alert } from 'reactstrap';

const ManagePermissions = ({ token, subuserId }) => {
    const [modal, setModal] = useState(false);
    const [permissions, setPermissions] = useState(null);
    const [phoneNumberInput, setPhoneNumberInput] = useState("");
    const [message, setMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [limits, setLimits] = useState({
        max_sms_daily: null,
        max_sms_monthly: null,
        max_sms_total: null,
    });
    const [limitsS, setLimitsS] = useState({
        max_sms_daily: null,
        max_sms_monthly: null,
        max_sms_total: null,
    });

    const toggle = () => {
        setModal(!modal);
        setMessage(null);
        setIsError(false);
    };

    const fetchPermissions = async () => {
        try {
            const response = await fetch(`${global.SERVIP}/user/get-subuser-permissions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ subuser_id: subuserId })
            });
            const result = await response.json();
            if (result.ok) {
                setPermissions(result.permissions);
                setLimits({
                    max_sms_daily: result.permissions.max_sms_daily || '',
                    max_sms_monthly: result.permissions.max_sms_monthly || '',
                    max_sms_total: result.permissions.max_sms_total || '',
                });
                setLimitsS({
                    max_sms_daily: result.permissions.max_sms_daily || '',
                    max_sms_monthly: result.permissions.max_sms_monthly || '',
                    max_sms_total: result.permissions.max_sms_total || '',
                });
            } else {
                setMessage('Failed to fetch permissions.');
                setIsError(true);
            }
        } catch (error) {
            setMessage('Error fetching permissions.');
            setIsError(true);
        }
    };

    useEffect(() => {
        if (modal) {
            fetchPermissions();
        }
    }, [modal]);

    const updatePermission = async (url, body) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(body)
            });
            const result = await response.json();
            if (result.ok) {
                fetchPermissions();

            } else {
                setMessage('Failed to update permission.');
                setIsError(true);
            }
        } catch (error) {
            setMessage('Error updating permission.');
            setIsError(true);
        }
    };

    const handleLimitChange = (field, value) => {
        setLimits({ ...limits, [field]: value });
    };

    const toggleUnlimited = (field, updateUrl) => {
        const isUnlimited = limits[field] === '';
        const newLimit = isUnlimited ? 100 : null;

        setLimits({ ...limits, [field]: newLimit });

        updatePermission(updateUrl, { subuser_id: subuserId, limit: newLimit });
    };

    const updateLimit = (url, field) => {
        const limitValue = limits[field] === '' ? null : parseInt(limits[field], 10);
        updatePermission(url, { subuser_id: subuserId, limit: limitValue });
    };

    const resetUsage = (url) => {
        updatePermission(url, { subuser_id: subuserId });
    };

    return (
        <>
            <a
                href="#toggle"
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    toggle();
                }}
                className="link link-primary"
            >
                Manage
            </a>
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Manage team member</ModalHeader>
                <ModalBody style={{ height: '80vh', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {message && (
                        <Alert color={isError ? 'danger' : 'success'}>
                            {message}
                        </Alert>
                    )}
                    {permissions && (
                        <>
                            {/* Limit Controls */}
                            <div className="d-flex justify-content-between" style={{ gap: '40px', overflowX: 'scroll', overflowY: 'auto', minHeight: 150 }}>
                                {[
                                    {
                                        label: 'Daily Limit',
                                        field: 'max_sms_daily',
                                        sentField: 'sent_sms_daily',
                                        updateUrl: '/user/update-subuser-daily-limit',
                                        resetUrl: '/user/reset-subuser-daily-sent',
                                    },
                                    {
                                        label: 'Monthly Limit',
                                        field: 'max_sms_monthly',
                                        sentField: 'sent_sms_monthly',
                                        updateUrl: '/user/update-subuser-monthly-limit',
                                        resetUrl: '/user/reset-subuser-monthly-sent',
                                    },
                                    {
                                        label: 'Total Limit',
                                        field: 'max_sms_total',
                                        sentField: 'sent_sms_total',
                                        updateUrl: '/user/update-subuser-total-limit',
                                        resetUrl: '/user/reset-subuser-total-sent',
                                    },
                                ].map(({ label, field, sentField, updateUrl, resetUrl }) => (
                                    <div key={field} className="d-flex flex-column w-100" style={{minWidth: 150}}>

                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'baseline', justifyContent: 'space-between' }}>
                                            <div>
                                                <h6>{label}</h6>
                                                <div>
                                                    <span>
                                                        Sent: {permissions[sentField]} /{' '}
                                                        {limits[field] === '' ? 'Unlimited' : limitsS[field]}
                                                    </span>
                                                </div>
                                            </div>

                                            <Button
                                                color="warning"
                                                size="sm"
                                                className="mt-2"
                                                onClick={() => resetUsage(`${global.SERVIP}${resetUrl}`)}
                                            >
                                                Reset
                                            </Button>
                                        </div><FormGroup check className="mt-2">
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    checked={limits[field] !== ''}
                                                    onChange={() => toggleUnlimited(field, `${global.SERVIP}${updateUrl}`)}
                                                />
                                                Set Limit
                                            </Label>
                                        </FormGroup>

                                        {limits[field] !== '' && (
                                            <div className="d-flex align-items-center mt-2 gap-2">
                                                <Input
                                                    type="number"
                                                    value={limits[field]}
                                                    min="1"
                                                    onChange={(e) => handleLimitChange(field, e.target.value)}
                                                />
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => updateLimit(`${global.SERVIP}${updateUrl}`, field)}
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div className="d-flex gap-4" style={{ height: '90%', overflow: 'hidden' }}>
                                {/* SIM Card Management Panel */}
                                <div className="w-50 pe-3 border-end d-flex flex-column">
                                    <h5>SIM Card Management</h5>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={permissions.use_all_devices}
                                                onChange={(e) =>
                                                    updatePermission(`${global.SERVIP}/user/update-subuser-use-all-devices`, {
                                                        subuser_id: subuserId,
                                                        use_all_devices: e.target.checked,
                                                    })
                                                }
                                            />
                                            Allow All Devices
                                        </Label>
                                    </FormGroup>
                                    <div
                                        className="flex-grow-1 mt-3"
                                        style={{
                                            overflowY: 'auto',
                                            border: '1px solid #ddd',
                                            padding: '10px',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <ul className="list-unstyled mb-0">
                                            {permissions.sim_cards.map((simCard) => (
                                                <li key={simCard.sim_card_id} className="mb-2">
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={simCard.has_permission}
                                                            disabled={permissions.use_all_devices}
                                                            onChange={(e) =>
                                                                updatePermission(
                                                                    e.target.checked
                                                                        ? `${global.SERVIP}/user/update-sim-card-right`
                                                                        : `${global.SERVIP}/user/remove-sim-card-right`,
                                                                    {
                                                                        sim_card_id: simCard.sim_card_id,
                                                                        subuser_id: subuserId,
                                                                        manage: false,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                        {simCard.name}
                                                    </Label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                {/* Phone Number Management Panel */}
                                <div className="w-50 ps-3 d-flex flex-column">
                                    <h5>Phone Number Management</h5>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={permissions.use_all_phone_numbers}
                                                onChange={(e) =>
                                                    updatePermission(`${global.SERVIP}/user/update-subuser-use-all-phone-numbers`, {
                                                        subuser_id: subuserId,
                                                        use_all_phone_numbers: e.target.checked,
                                                    })
                                                }
                                            />
                                            Allow All Phone Numbers
                                        </Label>
                                    </FormGroup>
                                    <div
                                        className="flex-grow-1 mt-3"
                                        style={{
                                            overflowY: 'auto',
                                            border: '1px solid #ddd',
                                            padding: '10px',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <ul className="list-unstyled mb-0">
                                            {permissions.phone_numbers.map((phoneNumber) => (
                                                <li key={phoneNumber} className="mb-2">
                                                    {phoneNumber}{' '}
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        disabled={permissions.use_all_phone_numbers}
                                                        onClick={() =>
                                                            updatePermission(
                                                                `${global.SERVIP}/user/remove-phone-number-right`,
                                                                { phone_number: phoneNumber, subuser_id: subuserId }
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    {!permissions.use_all_phone_numbers && (
                                        <div className="mt-3 d-flex align-items-center gap-2"
                                            style={{ marginBottom: 20 }}>
                                            <Input
                                                type="text"
                                                placeholder="Add Phone Number"
                                                value={phoneNumberInput}
                                                onChange={(e) => setPhoneNumberInput(e.target.value)}
                                            />
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    updatePermission(`${global.SERVIP}/user/update-phone-number-right`, {
                                                        phone_number: phoneNumberInput,
                                                        subuser_id: subuserId,
                                                    })
                                                }
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ManagePermissions;

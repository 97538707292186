import React, { useState, useEffect } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import moment from "moment-timezone";

const CustomInput = ({
  type = "text",           // "time", "number", or "timestamp"
  value,                   // For timestamp: a UNIX timestamp (ms)
  onChange,                // Callback to receive a new UNIX timestamp
  timezone = "UTC",        // The timezone to interpret the displayed time
  tooltipUp,
  tooltipDown,
  inputProps = {},
  ...rest
}) => {
  // For timestamp type, we maintain a wall-clock string like "YYYY-MM-DD HH:mm:ss"
  const [wallClock, setWallClock] = useState("");

  // When value or timezone changes, recalc the wallClock.
  useEffect(() => {
    if (type === "timestamp" && value) {
      // Interpret the UNIX timestamp in the selected timezone and format it.
      const wc = moment.tz(value, timezone).format("YYYY-MM-DD HH:mm:ss");
      setWallClock(wc);
    }
  }, [value, timezone, type]);

  // When the user changes the datetime, update wallClock and recalc the UNIX timestamp.
  const handleTimestampChange = (e) => {
    const inputVal = e.target.value; // Expected format: "YYYY-MM-DDTHH:mm" (or with seconds)
    // Convert to wall-clock format "YYYY-MM-DD HH:mm:ss"
    let wc = inputVal.replace("T", " ");
    if (wc.length === 16) {
      // If seconds are missing, add ":00"
      wc += ":00";
    }
    setWallClock(wc);
    // Interpret the wall-clock string as being in the selected timezone.
    const newTimestamp = moment.tz(wc, "YYYY-MM-DD HH:mm:ss", timezone).valueOf();
    onChange(newTimestamp);
  };

  // Prepare the value for the native datetime-local input.
  // The input expects a value like "YYYY-MM-DDTHH:mm" (without seconds).
  const inputValue =
    type === "timestamp" && wallClock
      ? moment(wallClock, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm")
      : "";

  // Render the appropriate input. For timestamp, we use the native input styled to look like Material‑UI.
  const renderedInput =
    type === "timestamp" ? (
      <input
        type="datetime-local"
        value={inputValue}
        onChange={handleTimestampChange}
        style={{
          width: "100%",
          padding: "12px 16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          fontSize: "14px",
          boxSizing: "border-box",
          outline: "none",
          backgroundColor: "#fff",
          transition: "border-color 0.2s ease-in-out",
        }}
        {...inputProps}
        {...rest}
      />
    ) : (
      // For other types, a basic input with similar styling.
      <input
        type={type}
        value={value}
        onChange={onChange}
        style={{
          width: "100%",
          padding: "12px 16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          fontSize: "14px",
          boxSizing: "border-box",
          outline: "none",
          backgroundColor: "#fff",
          transition: "border-color 0.2s ease-in-out",
        }}
        {...inputProps}
        {...rest}
      />
    );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", m: 1, width: 340 }}>
      {tooltipUp && (
        <Tooltip title={tooltipUp} arrow>
          <Typography variant="caption" sx={{ mb: 0.5, fontWeight: "bold" }}>
            {tooltipUp}
          </Typography>
        </Tooltip>
      )}
      {renderedInput}
      {tooltipDown && (
        <Tooltip title={tooltipDown} arrow>
          <Typography
            variant="caption"
            sx={{ mt: 0.5, color: "text.secondary", opacity: 0.6 }}
          >
            {tooltipDown}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export default CustomInput;

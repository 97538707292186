import React from "react";
import { Link } from "react-router-dom";
import './RecommendedArticles.css';
export default function RecommendedArticles({ articles }) {

  return (
    <div className="recommended-articles">
      <h4 className="recommended-title">Recommended Articles</h4>
      <ul className="recommended-list">
        {articles.map((article) => (
          <li key={article.slug} className="recommended-item">
            <Link to={`/${article.slug}`} className="recommended-link">
              {article.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

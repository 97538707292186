import { Link } from "react-router-dom";

export default function Breadcrumb({ title, subtitle, pagetitle }) {
  return (
    <section
      className="page-title-area title-area3"
      style={{
        backgroundImage: "url(assets/img/page-title/page-title-bg3.jpg)",
        padding: "20px 0", // Adjust padding values here to control height
      }}
    >
      <div className="container text-start">
        <p className="fs-18 mb-1">{subtitle}</p>
        <h2 className="page-title text-heding mb-1">{title}</h2>
        <ul className="breadcrumb list-none breadcrumb-bg mb-0">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active text-heding fw-bold" aria-current="page">
            {pagetitle}
          </li>
        </ul>
      </div>
    </section>
  );
}

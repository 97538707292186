import RouterPage from './pages/RouterPage';
import * as React from 'react'
import { ThemeProvider } from 'styled-components';
global.purchaseAmount = 0
global.advancedAnalytics = {}
function rtl() {
  return (
    <div></div>
  )
}
function topMenu() {
  return (
    <div />
  )
}
const darkMode = false;

const VIKTOR_IP = 'http://192.168.1.22'
const STEFAN_IP ='http://192.168.0.233'
const SERVER = 'https://textlinksms.com'
global.SERVIP = SERVER // 'http://127.0.0.1' // 
function App() {
  return (
    <RouterPage />
  );
}

export default App;

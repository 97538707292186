import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"
import { Row, Col, Button } from "reactstrap"
import { Accordian, PreviewAltCard } from "../../components/Component"
import {
    SpecialTable,
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    OrderTable,
    LoginLogTable,
} from "../../components/Component";
import {
    Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText, Spinner, FormText, Input
} from "reactstrap";
import { Icon } from "../../components/Component"
import BulkSmsFaq from "./components/BulkSmsFaq";
import { CampaignsTable } from "./components/CampaignsTable";
import { RecipientGroupsTable } from "./components/RecipientGroupsTable";
import UserGroup from "./components/UserGroup";
import { useEffect, useState } from "react";
import CraftMessageDnd from "./components/CraftMessageDnd";
import { PreviewCard } from "../../components/Component";
import SingleListDnd from "../../components/partials/dnd/SingleListDnd";
import CreateMessageComponent from "./components/CreateMessageComponent";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import Cookies from "js-cookie"
import { Select } from 'antd';
import SvgIncomplete from "./components/SvgIncomplete";
import SvgSpacer from "./components/SvgSpacer";
import SvgComplete from "./components/SvgComplete";
import TimezoneSelect from "./components/TimezoneSelect";
import { weekdays } from "moment";
import CustomInput from "./components/CustomInput";
import TimeIntervalInput from "./components/TimeIntervalInput";
import moment from "moment-timezone";

const { Option } = Select;

const WEEKDAYS = [1, 2, 3, 4, 5, 6, 0];
const WEEKDAY_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const DEFAULT_SELECTED = {};
for (let i = 0; i < 7; i++) {
    DEFAULT_SELECTED[i] = true;
}

function StartCampaign() {
    const token = Cookies.get('token');
    const [name, setName] = useState("");
    const [groupId, setGroupId] = useState(-1);
    const [groupMeta, setGroupMeta] = useState(null);
    const [fields, setFields] = useState([])
    const [firstContactValues, setFirstContactValues] = useState({})
    const [contactCountries, setContactCountries] = useState([])
    const [messagePrice, setMessagePrice] = useState(0)
    const [templateText, setTemplateText] = useState("")
    const [price, setPrice] = useState(0);
    const [groupVersions, setGroupVersions] = useState({});
    const [last, setLast] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [deliverablity, setDeliverability] = useState(1);
    const [isLoadingPrice, setIsLoadingPrice] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [useAllSims, setUseAllSims] = useState(true);
    const [simCards, setSimCards] = useState([]);
    const [stage, setStage] = useState(0);
    const [stage0Complete, setStage0Complete] = useState(false);
    const [stage1Complete, setStage1Complete] = useState(false);
    const [stage2Complete, setStage2Complete] = useState(false);
    const [stage3Complete, setStage3Complete] = useState(false);
    const [timezone, setTimezone] = useState(moment.tz.guess() || null);
    const [selectedDays, setSelectedDays] = useState(DEFAULT_SELECTED);
    const [startTime, setStartTime] = useState("09:00");
    const [endTime, setEndTime] = useState("18:00");
    const [wait, setWait] = useState(30);
    const [startTimestamp, setStartTimestamp] = useState(Date.now() + 3600000)
    const [timeIntervalUnit, setTimeIntervalUnit] = useState('Seconds');

    const formatPercent = (number) => {
        return Math.floor(parseFloat(number) * 100);
    }

    // JSON.stringify({groupId, version: groupVersions[groupId], text})
    const getChangeIdentifier = (id, version, text, useAllSims, simCards) => {
        return JSON.stringify({ id, version, text, selectedCountry, useAllSims, simCards });
    }
    const changeIdentifier = getChangeIdentifier(groupId, groupVersions[groupId], templateText, useAllSims, simCards);


    useEffect(() => {
        setStage0Complete((groupId && groupMeta?.contacts_length) ? true : false);
    }, [groupId]);

    useEffect(() => {
        setStage1Complete(!!templateText);
    }, [templateText]);

    useEffect(() => {
        if (stage == 2)
            setStage2Complete(true);
    }, [stage]);

    useEffect(() => {
        if (stage0Complete && stage1Complete && stage2Complete && (changeIdentifier == last))
            setStage3Complete(true);
        else
            setStage3Complete(false);
    }, [stage1Complete, stage2Complete, stage0Complete, changeIdentifier, last])

    const dailySMS = (() => {
        try {
            let [start_time_hours, start_time_minutes] = startTime.split(":").map(e => parseInt(e));
            let [end_time_hours, end_time_minutes] = endTime.split(":").map(e => parseInt(e));
            if (start_time_hours > end_time_hours)
                throw new Error("Error");
            if (start_time_hours == end_time_hours && start_time_minutes > end_time_minutes)
                throw new Error("Error");
            let total_seconds = (end_time_hours - start_time_hours) * 3600 + (end_time_minutes - start_time_minutes) * 60;
            if (start_time_hours == end_time_hours && start_time_minutes == end_time_minutes) {
                total_seconds = 86400;
            }
            return Math.ceil(total_seconds / wait);
        }
        catch (e) {
            console.log(e);
            return null;
        }
    })();

    const navigate = useNavigate()
    const handleInputChange = (e) => {
        const value = parseInt(e.target.value, 10);

        if (!isNaN(value)) {
            setWait(value);
            if (value < 30) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        } else {
            setWait(""); // Allow clearing the input field
        }
    };

    const handleBlur = () => {
        // Enforce minimum value when the user leaves the input
        if (wait < 30) {
            setWait(30);
            setShowWarning(false);
        }
    };
    const fetchGroupInfo = async (id) => {
        try {
            const url = `${global.SERVIP}/outreach/groups/get-campaign-info`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id })
            });
            if (!response.ok)
                return;
            const data = await response.json();
            if (!data.ok)
                return;

            setFields(data.fields);
            setFirstContactValues(data.first_contact_values);
            setContactCountries(data.contact_countries)
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        if (groupId > 0)
            fetchGroupInfo(groupId)
    }, [groupId])

    const startCampaign = async (name, group_id, message_text, pause) => {
        try {
            const url = `${global.SERVIP}/outreach/campaigns/create`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    name, group_id, message_text, pause, use_all_devices: useAllSims, sim_cards: simCards.filter(e => e.selected).map(e => e.id),
                    timezone,
                    selected_days: selectedDays,
                    start_time: startTime,
                    end_time: endTime,
                    start_timestamp: startTimestamp
                })
            });
            if (!response.ok)
                return { ok: false, message: "Server error" };
            const data = await response.json();
            if (!data.ok)
                return data;

            setFields(data.fields);
            setFirstContactValues(data.first_contact_values);
            setContactCountries(data.contact_countries)
            return data;
        }
        catch (e) {
            return { ok: false, message: "Server error" };
        }
    }

    useEffect(() => {
        if (!token) {
            navigate("/signup")
        }
        const fetchSimCards = async () => {
            try {
                const response = await fetch(`${global.SERVIP}/user/get-available-sims`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({})
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.ok)
                    setSimCards(data.sim_cards.map(e => ({ ...e, selected: false })));
            } catch (err) {
                console.log(err);
            } finally {
            }
        };

        fetchSimCards();
    }, []);

    const startPopup = async () => {
        Swal.fire({
            title: "Start this campaign",
            text: (changeIdentifier == last) ? `Your campaign will cost you ${price} TextLink SMS credits, do you want to start it` : "Are you sure you want to start the campaign without knowing its cost? If you want to see the campaign cost, close this dialog and click the \"Calculate deliverability rate and price\" button. ",
            icon: (changeIdentifier == last) ? "question" : "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result = await startCampaign(name, groupId, templateText, parseInt(wait) * 1000)
                if (result.ok) {
                    Swal.fire("Congratulations!", "Your campaign has started. Good luck with your leads.", "success");
                    navigate("/dashboard/bulksms-dashboard")
                }
                else {
                    Swal.fire("Error!", result.message, "error");
                }

            }
        });
    };
    const createNewCampaign = () => {
        if (!parseInt(wait) || parseInt(wait) < 30)
            return Swal.fire("Error!", "Wait amount should be a number greater than or equal to 30.", "error");
        if (!groupId || !templateText)
            return Swal.fire("Error!", "Select the group and enter text first.", "error");
        if (!useAllSims && simCards.every(e => {
            return !e.selected;
        }))
            return Swal.fire("Error!", "You need to select at least one SIM card.", "error");
        startPopup()
    }


    const calculatePrice = async (group_id, text) => {
        if (!groupId || !text)
            return Swal.fire("Error!", "Select the group and enter text first.", "error");
        if (!useAllSims && simCards.every(e => {
            return !e.selected;
        }))
            return Swal.fire("Error!", "You need to select at least one SIM card.", "error");

        setIsLoadingPrice(true);
        try {
            const url = `${global.SERVIP}/outreach/campaigns/calculate-price`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ group_id, text, source_country: selectedCountry || undefined, use_all_devices: useAllSims, sim_cards: simCards.filter(e => e.selected).map(e => e.id) })
            });
            if (!response.ok)
                return;
            const data = await response.json();
            if (!data.ok)
                return Swal.fire("Error!", data.message, "error");
            setPrice(data.price);
            setDeliverability(data.deliverability_rate)
            setLast(changeIdentifier);

        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsLoadingPrice(false); // Stop loading
        }
    }

    return (
        <>
            <Head title="Start SMS campaign" />
            <Content>
                <h4 style={{ marginBottom: 10 }}>Here you can create and start sms campaigns without code.</h4>
                <p style={{ marginBottom: 20 }}>Create/choose user groups with their respected attributes, piece together a message you want to send based on the user and start the campaign.</p>

                <Row>
                    <Col lg="8">
                        <Card className="card h-100">
                            <CardBody className="card-inner" >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", alignItems: 'center', justifyContent: 'start', marginBottom: 5 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', alignItems: 'center', marginBottom: '5px', paddingBottom: 7 }}>
                                            {stage0Complete ? <SvgComplete></SvgComplete> : <SvgIncomplete></SvgIncomplete>}
                                            <div onClick={() => { setStage(0); }} className="hover-color-change" style={{ color: (stage == 0) ? '#0d6efd' : '#212529', fontWeight: (stage == 0) ? '700' : '500' }}>
                                                Select recipients
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', alignItems: 'center', marginBottom: '5px', paddingBottom: 7 }}>
                                            <SvgSpacer></SvgSpacer>
                                            {stage1Complete ? <SvgComplete></SvgComplete> : <SvgIncomplete></SvgIncomplete>}
                                            <div onClick={() => { setStage(1); }} className="hover-color-change" style={{ color: (stage == 1) ? '#0d6efd' : '#212529', fontWeight: (stage == 1) ? '700' : '500' }}>
                                                Edit message
                                            </div>
                                        </div>



                                        <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', alignItems: 'center', marginBottom: '5px', paddingBottom: 7 }}>
                                            <SvgSpacer></SvgSpacer>
                                            {stage2Complete ? <SvgComplete></SvgComplete> : <SvgIncomplete></SvgIncomplete>}
                                            <div onClick={() => { setStage(2); }} className="hover-color-change" style={{ color: (stage == 2) ? '#0d6efd' : '#212529', fontWeight: (stage == 2) ? '700' : '500' }}>
                                                Setup
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', alignItems: 'center', marginBottom: '5px', paddingBottom: 7 }}>
                                            <SvgSpacer></SvgSpacer>
                                            {stage3Complete ? <SvgComplete></SvgComplete> : <SvgIncomplete></SvgIncomplete>}
                                            <div onClick={() => { setStage(3); }} className="hover-color-change" style={{ color: (stage == 3) ? '#0d6efd' : '#212529', fontWeight: (stage == 3) ? '700' : '500' }}>
                                                Final review
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div style={{ width: "100%", height: 1, background: "rgb(218, 221, 236)", marginBottom: 25 }}></div>

                                {stage == 2 && <div>
                                    <h5>Choose a name for your new campaign</h5>
                                    <p>Choose a name for your campaign. This can be anything you like. (Sales prospects, Discount reminder, ...)</p>
                                    <br />
                                    {/* Campaign Name */}
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <input className="form-control form-control-md" placeholder="Campaign Name" style={{ maxWidth: '400px', marginBottom: '20px' }} value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <br />
                                </div>}
                                {stage == 0 && <div>
                                    <h5>Choose or create user group</h5>
                                    <p>Choose your custumer group, or create a new one.<br /> Each group has 1 mandatory column (phone_number). Groups are imported from Excel file (each coulmn is a new value, you can add anything you like, address, name, surname, ...), or created manualy. Check out our video on creating groups <a style={{ color: 'blue' }} href="https://www.youtube.com/watch?v=-gajhLRMYIE">here</a></p>
                                    <br />
                                    <div style={{ marginBottom: '20px', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                        <RecipientGroupsTable saveGroupUpdateCallback={(id) => {
                                            if (groupVersions[id]) {
                                                groupVersions[id]++;
                                            }
                                            else {
                                                groupVersions[id] = 1;
                                            }
                                            setGroupVersions({ ...groupVersions });
                                        }} selectable={true} selectedId2={groupId} setSelected={setGroupId} setSelectedMeta={setGroupMeta}></RecipientGroupsTable>
                                    </div>
                                    <br />
                                </div>}
                                {/* Message Creation */}
                                {stage == 1 && <div>
                                    <h5>Enter your message template</h5>
                                    <p>Now that you have chosen the recipient group, it is time to enter your message text. You can also use the custom fields you defined for your group in the previous step. </p>
                                    <br />
                                    <PreviewCard>
                                        <CreateMessageComponent templateText={templateText} setTemplateText={setTemplateText} fields={fields} firstContactValues={firstContactValues} contactCountries={contactCountries} setMessagePrice={setMessagePrice} />
                                    </PreviewCard>
                                    <br />
                                </div>}

                                {stage == 2 && <div>
                                    <h5>Schedule campaign</h5>

                                    <p>Choose timezone
                                    </p>
                                    <TimezoneSelect timezone={timezone} setTimezone={setTimezone}></TimezoneSelect>
                                    <br></br>
                                    <p>Send these days</p>
                                    <div style={{ display: "flex", flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
                                        {WEEKDAYS.map(e => {
                                            return <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <input style={{ marginRight: 6, cursor: 'pointer', height: 17, width: 17 }} id={"weekday" + e} type="checkbox" checked={selectedDays[e]} onChange={newE => {
                                                    setSelectedDays(oldSel => {
                                                        const newSel = structuredClone(oldSel);
                                                        if (newE.target.checked) {
                                                            newSel[e] = true;
                                                        }
                                                        else {
                                                            delete newSel[e];
                                                        }
                                                        return newSel;
                                                    })
                                                }}></input>
                                                <label style={{ fontSize: 15 }} for={"weekday" + e}>{WEEKDAY_NAMES[e]}</label>
                                            </div>
                                        })}
                                    </div>
                                    <br></br>
                                    <p>Choose the time period</p>
                                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", flexWrap: 'wrap' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                                            <CustomInput type="time" value={startTime} onChange={e => setStartTime(e.target.value)} tooltipUp={"From"}></CustomInput>
                                            <SvgSpacer></SvgSpacer>
                                            <CustomInput type="time" value={endTime} onChange={e => setEndTime(e.target.value)} tooltipUp={"To"}></CustomInput>
                                        </div>
                                        <TimeIntervalInput timeIntervalUnit={timeIntervalUnit} setTimeIntervalUnit={setTimeIntervalUnit} value={wait} onChange={e => {
                                            setWait(e)
                                        }} tooltipUp={"An SMS will be sent every:"} tooltipDown={dailySMS + " SMS will be sent / day"} warning={(wait < 30) ? "Minimum 30 seconds" : (dailySMS ? false : "Can't compute daily SMS")}></TimeIntervalInput>
                                    </div>
                                    <p>When do you want your campaign to start?</p>
                                    <CustomInput timezone={timezone} type="timestamp" value={startTimestamp} onChange={e => setStartTimestamp(e)}></CustomInput>
                                    <br></br>
                                </div>}

                                {stage == 2 && <div>
                                    <h5>Choose SIM cards to be used for this campaign (optional)</h5>
                                    <p>You can choose to only use specific SIM cards by unticking the check box below.
                                    </p> <br></br>
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                        <input id="availablesims" type="checkbox" checked={useAllSims} onChange={(e) => { setUseAllSims(e.target.checked) }} style={{ marginRight: '10px' }} />
                                        <label style={{ cursor: 'default' }} for="availablesims">Use all available SIM cards to send messages for this Campaign</label>
                                    </div>
                                    {!useAllSims && <div style={{ textAlign: 'center', paddingTop: 10 }}>
                                        {simCards.map(sim_card => {
                                            const { id, name, selected } = sim_card;
                                            return <div key={id} style={{ marginTop: 5, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                                <input id={"availablesims" + id} type="checkbox" checked={selected} onChange={(e) => {
                                                    setSimCards(oldSimCards => {
                                                        const newSimCards = structuredClone(oldSimCards);
                                                        for (let i = 0; i < newSimCards.length; i++) {
                                                            if (newSimCards[i].id == id) {
                                                                newSimCards[i].selected = !newSimCards[i].selected
                                                            }
                                                        }
                                                        return newSimCards;
                                                    })
                                                }} style={{ marginRight: '10px' }} />
                                                <label style={{ cursor: 'default' }} for={"availablesims" + id}>{name}</label>
                                            </div>
                                        })}
                                    </div>}
                                    <br />
                                </div>}

                                {stage == 3 && <div>
                                    <div style={{ textAlign: "center", marginTop: 50 }}>
                                        <p><b>Campaign name: </b> {name || "Undefined"}</p>
                                        <br></br>
                                        <p><b>Selected group: </b> {groupId ? (groupMeta?.name + " (" + groupMeta?.contacts_length + ")") : "None"}</p>
                                        <p style={{ whiteSpace: 'pre-line' }}><b>Message template: </b>{templateText}</p>
                                        <br></br>
                                        <p><b>Time zone: </b> {timezone}</p>
                                        <p><b>Days to send: </b> {[1, 2, 3, 4, 5, 6, 0].reduce((p, c) => p + (
                                            selectedDays[c] ? WEEKDAY_NAMES[c] + " " : ''
                                        ), "")}</p>
                                        <p><b>Sending time: </b>{startTime} - {endTime}</p>
                                        <p><b>Interval: </b>{(wait % 3600 == 0) ? (wait / 3600) + " hours" : ((wait % 60 == 0) ? (wait / 60) + " minutes" : wait + " seconds")}</p>
                                        <p><b>Campaign start:</b> {new Date(startTimestamp).toLocaleString('en-US', { timeZone: timezone })}</p>
                                        <br></br>
                                        <p><b>Sending SIM cards:</b> {useAllSims ? "All" : simCards.reduce((p, c) => ((c.selected ? 1 : 0) + p), 0)}</p>
                                        <br></br>
                                        {(changeIdentifier == last) ? (<span style={{
                                            marginLeft: 10,
                                            display: "inline-block",
                                            fontSize: "16px",
                                            lineHeight: "1.5",
                                            color: "#333",
                                            padding: "5px 10px",
                                            backgroundColor: "#f9f9f9",
                                            borderRadius: "5px",
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                                        }}>
                                            {`Deliverability rate: ${formatPercent(deliverablity)}%`}
                                            <br />
                                            {`Price: ${price} SMS credits`}
                                        </span>
                                        ) :
                                            <Button
                                                color="primary"
                                                outline={true}
                                                style={{ display: "inline", marginLeft: 10 }}
                                                onClick={() => calculatePrice(groupId, templateText)}
                                                disabled={isLoadingPrice} // Disable button while loading
                                            >
                                                {isLoadingPrice ? <Spinner size="sm" /> : "Calculate deliverability rate and price"}
                                            </Button>}
                                    </div>
                                    <br />
                                </div>
                                }

                                {stage == 3 && <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button color="primary" style={{ width: '70%' }} onClick={() => { createNewCampaign() }}>Start campaign</Button>

                                </div>}

                                <br />


                                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', width: "100%" }}>
                                    <Button color="primary" outline={true} style={{ opacity: (stage == 0) ? 0 : 1 }} disabled={stage == 0} onClick={() => {
                                        setStage(prev => (prev - 1))
                                    }}>Go Back</Button>
                                    <Button color="primary" outline={true} style={{ opacity: (stage == 3) ? 0 : 1 }} disabled={stage == 3} onClick={() => {
                                        setStage(prev => (prev + 1))
                                    }}>Next</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
                        {/* Troubleshooting Section */}
                        <Card className="card h-100">
                            <CardBody className="card-inner">
                                <CardTitle tag="h6">Having trouble?</CardTitle>
                                <p className="mb-2 ff-base">
                                    Check out this video for help. It will show you how to use this tool in just a couple of minutes.
                                </p>
                                {/* Embed YouTube Video */}
                                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                                    <iframe
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                        src="https://www.youtube.com/embed/-gajhLRMYIE" // Replace VIDEO_ID with your video's ID
                                        title="YouTube video"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Content >
        </>
    );
}

export default StartCampaign
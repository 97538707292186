import React, { useState, useEffect } from "react";
import {
    Box,
    TextField,
    Typography,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";

const TimeIntervalInput = ({ timeIntervalUnit, setTimeIntervalUnit, value = 0, onChange, tooltipUp, tooltipDown, warning }) => {
    // Unit multipliers:
    // 1 → Seconds
    // 60 → Minutes
    // 3600 → Hours
    const [unit, setUnit] = useState((timeIntervalUnit == 'Seconds') ? 1 : (timeIntervalUnit == 'Minutes' ? 60 : 3600)); // Default unit: seconds
    const [localNumber, setLocalNumber] = useState(value / unit); // Prevent undefined errors

    useEffect(() => {
        setTimeIntervalUnit((unit == 1) ? 'Seconds' : (unit == 60 ? 'Minutes' : 'Hours'))
    }, [unit])

    // Update displayed number if value or unit changes externally
    useEffect(() => {
        setLocalNumber(value / unit || 0); // Ensure a valid number
    }, [value, unit]);

    useEffect(() => {
        if (parseInt(localNumber) == 0) {
            setLocalNumber(1);
        }
    }, [localNumber])


    // Handle changes in the number field
    const handleNumberChange = (e) => {
        const newLocal = Number(e.target.value) || 0; // Prevent NaN issues
        setLocalNumber(newLocal);
        onChange(newLocal * unit); // Convert back to seconds
    };

    // Handle unit selection changes
    const handleUnitChange = (e) => {
        const newUnit = Number(e.target.value);
        const newLocal = Number(localNumber) || 0; // Ensure it's a number
        setUnit(newUnit);
        onChange(newLocal * newUnit); // Convert to seconds
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
            {tooltipUp && (
                <Typography
                    variant="caption"
                    sx={{ mb: 0.5, fontWeight: "bold" }}
                    title={tooltipUp}
                >
                    {tooltipUp}
                </Typography>
            )}
            <Box sx={{ display: "flex", alignItems: "center", width: 240 }}>
                <TextField
                    type="number"
                    value={localNumber}
                    onChange={handleNumberChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        flex: 1,
                        "& .MuiOutlinedInput-root": {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                    }}
                />
                <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                    <Select
                        value={unit}
                        onChange={handleUnitChange}
                        sx={{
                            borderRadius: "0 4px 4px 0",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderLeft: "none",
                            },
                        }}
                    >
                        <MenuItem value={1}>Seconds</MenuItem>
                        <MenuItem value={60}>Minutes</MenuItem>
                        <MenuItem value={3600}>Hours</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {tooltipDown && (
                <Typography
                    variant="caption"
                    sx={{ mt: 0.5, color: warning ? "red" : "text.secondary", opacity: 0.6, fontWeight: warning ? 'bold' : 'normal' }}
                    title={tooltipDown}
                >
                    {warning || tooltipDown}
                </Typography>
            )}
        </Box>
    );
};

export default TimeIntervalInput;

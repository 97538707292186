import React from "react";
import { useEffect, useState } from "react";
import Cookies from "js-cookie"
import UserGroup from "./UserGroup";
import { Button } from "../../../components/Component";

import { Modal } from "reactstrap";

export const RecipientGroupsTable = ({ selectable, selectedId2, setSelected, setSelectedMeta, saveGroupUpdateCallback }) => {
  const token = Cookies.get('token');
  const [groupId, setGroupId] = useState(0);
  const [groups, setGroups] = useState([]);
  const [selectedId, setSelectedId] = useState(-1);

  useEffect(() => {
    if (selectable) {
      setSelected(selectedId);


      console.log(groups);
      console.log(setSelectedMeta);
      if (setSelectedMeta) {
        console.log("SSM");
        for (const group of groups) {
          console.log(group);
          if (group.id === selectedId)
            setSelectedMeta(group);
        }
      }

    }
  }, [selectedId])

  const fetchGroups = async () => {
    try {
      const url = `${global.SERVIP}/outreach/groups/get-all`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
      if (!response.ok)
        return;
      const data = await response.json();
      if (!data.ok)
        return;
      setGroups(data.groups);
      if (data.groups.length > 0) {
        setSelectedId(selectedId2 || data.groups[0].id);
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    fetchGroups();
  }, [])
  const createGroup = async () => {
    try {
      const url = `${global.SERVIP}/outreach/groups/create`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
      if (!response.ok)
        return;
      const data = await response.json();
      if (!data.ok)
        return;

      if (groups.length == 0) {
        setSelectedId(data.group.id)
      }
      setGroups([{ ...data.group, contacts_length: 0 }, ...groups]);
      setGroupId(data.group.id)
    }
    catch (e) {
      console.log(e);
    }
  }

  const highlightColor = '#EEF0FF';
  const highlightTextColor = '#6576FF';

  return (<div style={{ width: "100%" }}>
    <Button className="btn-dim" outline color="primary" style={{ width: "100%", marginBottom: 10, marginTop: 10 }} onClick={createGroup}> Create new</Button>

    <table className={`table table-tranxf`}>
      <thead>
        <tr className="tb-tnx-item">
          <th className="tb-tnx-info">
            Name
          </th>
          <th className="tb-tnx-info">
            Recipients
          </th>
          <th className="tb-tnx-info">
            Created at
          </th>
          {selectable ?
            <th className="tb-tnx-info">
            </th> : null}
        </tr>
      </thead>
      <tbody>
        {groups.map((item) => {
          return (
            <tr key={item.id} className="tb-tnx-item" onClick={() => selectable ? setSelectedId(item.id) : setGroupId(item.id)}>

              <td className="tb-tnx-info" style={{ backgroundColor: (selectable && (item.id == selectedId)) ? highlightColor : undefined, color: (selectable && (item.id == selectedId)) ? highlightTextColor : undefined }}>
                <span className="title">{item.name || 'Unnamed'}</span>
              </td>
              <td className="tb-tnx-info" style={{ backgroundColor: (selectable && (item.id == selectedId)) ? highlightColor : undefined, color: (selectable && (item.id == selectedId)) ? highlightTextColor : undefined }}>
                <span className="title">{item.contacts_length}</span>
              </td>
              <td className="tb-tnx-info" style={{ backgroundColor: (selectable && (item.id == selectedId)) ? highlightColor : undefined, color: (selectable && (item.id == selectedId)) ? highlightTextColor : undefined }}>
                <span>{new Date(item.createdAt).toLocaleDateString()}</span>
              </td>
              {selectable ?
                <td className="tb-tnx-info" style={{ backgroundColor: (selectable && (item.id == selectedId)) ? highlightColor : undefined, color: (selectable && (item.id == selectedId)) ? highlightTextColor : undefined }}>
                  <a
                    href="#toggle"
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      setGroupId(item.id);
                    }}
                    className="link link-primary"
                  >
                    Edit
                  </a>
                </td> : null}
            </tr>
          );
        })}
        {(groups.length == 0) ? <tr><td colSpan={4} style={{ textAlign: "center" }}>You do not have any groups. </td></tr> : null}
      </tbody>
    </table>
    <Modal isOpen={groupId ? true : false} size="lg" toggle={() => { }}>
      <UserGroup groupId={groupId} close={() => setGroupId(0)} deleteCallback={(id) => { if (id == selectedId) setSelectedId(-1) }} setGroups={setGroups} saveCallback={({ id }) => {
        setSelectedId(_ => -1);
        setTimeout(() => {
          setSelectedId(_ => id);
          if (saveGroupUpdateCallback) {
            saveGroupUpdateCallback(id);
          }
        }, 500)
      }} />
    </Modal>
  </div>
  );
};

// SubscriptionsCard.js

import React from "react";
import { DataTableHead, DataTableRow, DataTableItem } from "../../components/Component";
import { Button } from "reactstrap";
import { Icon } from "../../components/Component";
import { useNavigate } from "react-router";
import Cookies from 'universal-cookie';

const SubscriptionsCard = ({ subscriptions }) => {
  const navigate = useNavigate();

  const cookies = new Cookies();
  if (cookies.get('subuser')?.toString() === 'true')
    return <div style={{ height: 250, display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}><b>Coming soon</b></div>;

  return (
    <React.Fragment>
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">Active Subscriptions</h6>
            <p>Manage your current subscriptions. The subscription resets every month. </p>
          </div>
        </div>
      </div>
      <div className="nk-tb-list is-loose traffic-channel-table">
        <DataTableHead>
          <DataTableRow className="nk-tb-channel">
            <span>Subscription Name</span>
          </DataTableRow>
          <DataTableRow className="nk-tb-sessions">
            <span>Messages Left</span>
          </DataTableRow>
          <DataTableRow className="nk-tb-prev-sessions">
            <span>Cost per SMS</span>
          </DataTableRow>
        </DataTableHead>
        {subscriptions.length > 0 ? (
          subscriptions.map((subscription, index) => (
            <DataTableItem className="nk-tb-item" key={index}>
              <DataTableRow className="nk-tb-channel">
                <span className="tb-lead">{subscription.name}</span>
              </DataTableRow>
              <DataTableRow className="nk-tb-sessions">
                <span className="tb-sub tb-amount">
                  <span>{subscription.messagesLeft}</span>
                </span>
              </DataTableRow>
              <DataTableRow className="nk-tb-prev-sessions">
                <span className="tb-sub tb-amount">
                  <span>${subscription.costPerMessage}</span>
                </span>
              </DataTableRow>
            </DataTableItem>
          ))
        ) : (
          <div className="text-center p-3">
            <p>You have no active subscriptions.</p>
          </div>
        )}
        <Button onClick={() => navigate("/dashboard/pricing")} color="primary" className="btn-sm" style={{ margin: 15 }}>
          <Icon name="reports" />
          <span>Manage Subscriptions</span>
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionsCard;

import './CtaBanner.css';
import { Link } from 'react-router-dom';
const CtaBanner = () => (
    <div className="cta-banner">
      <h4>Try TextLink for Free</h4>
      <p>
        Sign up today and get 500 messages for free to explore seamless communication.
      </p>
      <Link 
        to="/signup"
        className="cta-button"
        style={{color:'white'}}
      >
        Try for Free
      </Link>
    </div>
  );

export default CtaBanner;